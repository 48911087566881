<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      id="print"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه التقيمات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />

      <Column field="contractsId.surveysList" header="% رضاء العميل">
        <template #body="slotProps">
          <Knob
            :value="getSurveyRating(slotProps.data.contractsId.surveysList)"
            max="100"
            disabled
          />
        </template>
      </Column>

      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.contractsId.surveysList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه التقيمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="surveysId.name" header="بنود التقيم" />
            <Column field="rating" header="التقيم" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      deliveryStepsList: [],
      tmpData: null,
      // add servay
      contractsId: null,
      disabledAdd: false,
      contractsList: [],
      surveysList: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    getSurveyRating(list) {
      const total = list.reduce((val, item) => {
        return val + item.surveysId.ratingLimit;
      }, 0);
      const p = 100 / total;
      const rating = list.reduce((val, item) => {
        return val + item.rating;
      }, 0);
      return (p * rating).toFixed(2);
    },
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    getData() {
      this.$http.get(`delivery/getAllDataToWelcomeReport`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    update() {
      if (this.contractsId) {
        this.disabledAdd = true;
        this.$http
          .put(`contracts/surveysList/${this.contractsId.id}`, {
            surveysList: this.contractsId.surveysList,
          })
          .then(
            () => {
              // this.$toast.add({
              //   severity: 'success',
              //   summary: 'تم بنجاح',
              //   detail: 'تم التعديل بنجاح',
              //   life: 3000,
              // });
              this.displayBasic = false;
              this.addDel();
              this.disabledAdd = false;
              // this.$router.push('/admin/company');
            },
            (err) => {
              this.disabledAdd = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
    this.$http.get(`surveys`).then((res) => {
      this.surveysList = res.data;
    });
  },
  watch: {
    contractsId(val) {
      if (val && val.surveysList) {
        // list = [];
        for (const item of this.surveysList) {
          if (!val.surveysList.find((el) => el.surveysId.id == item.id)) {
            val.surveysList.push({
              surveysId: item,
              rating: 0,
            });
          }
        }
      }
    },
  },
};
</script>
